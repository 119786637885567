import React from "react"
import "./about.module.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import CTA from "../components/cta"
import Logo from "../images/board-logo.svg"

const SecondPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <div styleName="hero">
      <div styleName="heroFlex">
        <div styleName="flexCenter">
          <h1>Welcome to SHAPE</h1>

          <p>
            We help companies improve their productivity through their people.
            We provide the instrument which assesses the need, and the
            strategies to optimise every employee’s desire and ability to turn
            up and perform at work. ​
          </p>
        </div>

        <div>
          <img src={Logo} alt="SHAPE AI" />
        </div>
      </div>
    </div>
    <div styleName="content">
      <div styleName="contentDesc">
        <p>
          At our core, we’re solving the hardest workplace problems through
          data, science and creative design. Our deep tech approach has allowed
          us to take on one of the biggest challenge of our times – measure and
          grow employee, teams and leadership productivity in any company,
          industry, and country, or any combination thereof.
          <br />
          <br />
          ​SHAPE is the System for Health, Attendance, Productivity and
          Engagement. Founded by <b>Dr. John Lang</b> and <b>Ali Khan</b>, our
          background is in delivering hands-on company change through its
          people. We help companies understand the art and science of people
          performance. Through our work, we inspire actions that create
          sustainable behaviour change through the unification of data
          intelligence needed to enhance people productivity and drive employee
          value.
        </p>
      </div>
      <div styleName="text">
        <div styleName={"card"}>
          <Img fixed={data.john.childImageSharp.fixed} alt="John Lang" />
          <h3>Dr. John Lang</h3>
          <span>CEO</span>
          <p>
            John Lang, PhD has over thirty years of experience as a leader in
            the field of employee wellbeing, including the establishment of the
            industry body which he led for six years. He has led several
            organisations to market leader status in Australia, including Alere
            Health and Good Health Solutions from founder through to acquisition
            status.
            <br />
            <br />
            With a background in both mathematics and medicine, John's health
            risk prediction algorithms power health assessments the world over.
            The application of these advanced techniques to the SHAPE platform
            widens the scope from physical and psychological health of employees
            to include critical performance-related areas of culture,
            engagement, work/life balance, management style and other elements
            of the work environment that determine the people productivity
            enablers at work.
          </p>
        </div>
        <div styleName={"card"}>
          <Img fixed={data.ali.childImageSharp.fixed} alt="Ali Khan" />
          <h3>Ali Khan</h3>
          <span>COO</span>
          <p>
            Ali Khan is a healthcare executive and entrepreneur. He is known for
            founding, developing, and delivering transformative health ventures
            that scale globally. <br />
            <br />
            Ali's experience spans over two decades, most recently developing
            and scaling the global insurer AXA's flagship well-being services as
            their Chief Science Innovation Officer, and as Co-Founder & CIO of
            next generation infectious disease diagnostics company SensusDx,
            Inc. <br />
            <br />
            Ali is also an avid investor, and advisor to multiple start-ups,
            corporate and academic institutions.
          </p>
        </div>
      </div>
    </div>
    <CTA isFreeReport={true} />
  </Layout>
)

export default SecondPage

export const query = graphql`
  query {
    john: file(relativePath: { eq: "johnLangImg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    ali: file(relativePath: { eq: "aliKhanImg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
